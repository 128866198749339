<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('drug_equipment_analysis_report') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters
        :default-status="1"
        @onSendDate="addPayload"
      />
      <v-row class="px-2 mb-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="serviveTypeSelect"
            :items="serviceTypeList"
            outlined
            dense
            class="set-input"
            :label="$t('service_type')"
            :item-text="$i18n.locale"
            item-value="value"
            hide-details
            @change="addPayload(payload.start, payload.end)"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.drug_name`]="{ item }">
          <span
            :class=" item.drug_name == $t('total') ? 'font-weight-bold primary--text' : ''"
          >
            {{ item.drug_name }}
          </span>
        </template>
        <template v-slot:[`item.drug_price`]="{ item }">
          <span
            :class="
              item.drug_name == $t('total') ? 'font-weight-bold primary--text' : ''
            "
          >
            {{ item.drug_price }}
          </span>
        </template>
        <template v-slot:[`item.customer_old`]="{ item }">
          <span
            :class="
              item.drug_name == $t('total') ? 'font-weight-bold primary--text' : ''
            "
          >
            {{ item.customer_old }} | {{ item.customer_old_price }}
          </span>
        </template>
        <template v-slot:[`item.customer_new`]="{ item }">
          <span
            :class="
              item.drug_name == $t('total') ? 'font-weight-bold primary--text' : ''
            "
          >
            {{ item.customer_new }} | {{ item.customer_new_price }}
          </span>
        </template>
        <template v-slot:[`item.customer_all`]="{ item }">
          <span
            :class="
              item.drug_name == $t('total') ? 'font-weight-bold primary--text' : ''
            "
          >
            {{ item.customer_all }}
          </span>
        </template>
        <template v-slot:[`item.drug_count`]="{ item }">
          <span
            :class="
              item.drug_name == $t('total') ? 'font-weight-bold primary--text' : ''
            "
          >
            {{ item.drug_count }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportDrugAnalysis, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate } from '@/plugins/filters'
import serviceTypeForSearch from '@/@fake-db/data/serviceTypeForSearch.json'

export default {
  components: {
    Comeback,
    DateFilters,
  },
  setup() {
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)
    const options = ref({})
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('list_drug_equipment'), value: 'drug_name', width: 280 },
      {
        text: '%', value: 'persent', width: 100, align: 'end',
      },
      {
        text: i18n.t('sales'), value: 'drug_price', width: 280, align: 'end',
      },
      {
        text: i18n.t('customers_old'), value: 'customer_old', width: 200, align: 'end',
      },
      {
        text: i18n.t('customers_new'), value: 'customer_new', width: 200, align: 'end',
      },
      {
        text: i18n.t('customers_transactions'), value: 'customer_all', width: 120, align: 'end',
      },
      {
        text: i18n.t('amount'), value: 'drug_count', width: 100, align: 'end',
      },
      {
        text: i18n.t('male'), value: 'customer_male', width: 100, align: 'end',
      },
      {
        text: i18n.t('female'), value: 'customer_female', width: 100, align: 'end',
      },
      {
        text: i18n.t('age_range'), value: 'customer_age', width: 100, align: 'center',
      },
    ])
    const dataDetail = ref([])
    const serviceTypeList = ref(serviceTypeForSearch.data)
    const serviveTypeSelect = ref('')

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        out_id: serviveTypeSelect.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data, dataDetail: data2 } = await reportDrugAnalysis(payload)
      dataTableList.value = data
      dataDetail.value = data2
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('drug_analysis_report')} ${i18n.t(
        'since',
      )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [
          `${i18n.t('drug_analysis_report')} ${i18n.t(
            'since',
          )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}`,
        ],
        [
          '#',
          `${i18n.t('list_drug_equipment')}`,
          '%',
          `${i18n.t('sales')}`,
          `${i18n.t('customers_old')}`,
          '#',
          `${i18n.t('customers_new')}`,
          '#',
          `${i18n.t('customers_transactions')}`,
          `${i18n.t('operative_purchased')}`,
          `${i18n.t('male')}`,
          `${i18n.t('female')}`,
          `${i18n.t('age_range')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(
        ws,
        await removeComma(JSON.parse(JSON.stringify(dataTableList.value))),
        {
          header: [
            'number',
            'drug_name',
            'persent',
            'drug_price',
            'customer_old',
            'customer_old_price',
            'customer_new',
            'customer_new_price',
            'customer_all',
            'drug_count',
            'customer_male',
            'customer_female',
            'customer_age',
          ],
          skipHeader: true,
          origin: -1,
        },
      )
      const wb = XLSX.utils.book_new()
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 1, c: 7 } },
      ]
      const wscols = [
        { wch: 6 },
        { wch: 20 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${i18n.t('drug_analysis_report')}`,
      )

      /*  --  */
      const Heading2 = [
        [
          `${i18n.t('list_of_customers_by_sales')} ${i18n.t(
            'since',
          )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}`,
        ],
        [
          i18n.t('customer_code'),
          `${i18n.t('prefix')}`,
          `${i18n.t('firstname')}`,
          `${i18n.t('lastname')}`,
          i18n.t('tel'),
          i18n.t('group_customers'),
          i18n.t('id_card'),
          i18n.t('gender'),
          i18n.t('email'),
          i18n.t('blood_type'),
          i18n.t('birthday'),
          i18n.t('old'),
          i18n.t('address'),
          i18n.t('tag'),
          i18n.t('examination_list'),
          i18n.t('sale'),
          i18n.t('quantity'),
          i18n.t('user_is_list'),
        ],
      ]
      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(
        ws2,
        await removeComma(
          JSON.parse(JSON.stringify(dataDetail.value)),
        ),
        {
          header: [
            'customer_id',
            'customer_prefix',
            'customer_fname',
            'customer_lname',
            'customer_tel',
            'customer_group_name',
            'customer_idcard',
            'customer_gender',
            'customer_email',
            'customer_blood',
            'customer_birthdate',
            'customer_age',
            'customer_address',
            'customer_tags',
            'course_name',
            'total_price',
            'count',
          ],
          skipHeader: true,
          origin: -1,
        },
      )
      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 14 } }]
      const wscols2 = [
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 10 },
        { wch: 25 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 60 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2

      XLSX.utils.book_append_sheet(
        wb,
        ws2,
        `${i18n.t('list_of_customers_by_sales')}`,
      )

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      payload,
      loading,
      exportLoading,
      options,
      dataTableList,
      columns,
      dataDetail,
      serviceTypeList,
      serviveTypeSelect,
      addPayload,
      exportExcel,
      mdiFileExcelOutline,
    }
  },
}
</script>
